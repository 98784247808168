import React, { useEffect } from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../components/layouts/login'
import { navigateTo } from '../components/shared'
import { getRequest } from '../services/api'
import { loginDemo } from '../services/auth'

export default function DemoPage(props: PageProps) {
  useEffect(() => {
    const callback = async () => {
      const response = await getRequest('/auth/v1/login/demo')
      loginDemo(response.data)
      navigateTo('/' + location.search)
    }
    callback()
  }, [])

  return <Layout />
}
